<template>
  <b-col sm="12" class="pb-5 p-1">
    <div class="bd-card rounded-3 bg-light">
      <div class="p-3 d-flex align-items-center f-14 bold">
        <b-icon icon="briefcase-fill" style="color: #159895" class="mr-2" />
        LIST SALDO
      </div>
      <div class="d-flex flex-column m-2">
        <b-tabs content-class="pt-1" align="center" justified>
          <b-tab :title-link-class="'tab-title-class'" active>
            <template #title>
              <i class="mr-1 f-12">List </i>
              <strong class="f-12">Tabungan</strong>
            </template>
            <div
              class="d-flex justify-content-center align-items-center flex-column p-5"
              v-if="saldo.loading"
            >
              <b-spinner
                class="f-12"
                type="grow"
                label="Spinning"
                style="background-color: #159895"
              ></b-spinner>
              <span class="f-11 mt-1 bold">Loading....</span>
            </div>

            <div v-else>
              <div
                class="m-2"
                v-for="(tab, tabIndex) in saldo.tabungan"
                :key="`tab-${tabIndex}`"
              >
                <div class="bg-card-tabungan f-12 text-white p-2">
                  <h6 class="f-14">{{ tab.product_name }}</h6>
                  <h2 class="f-14 bold">{{ tab.saldo }}</h2>
                  <div class="d-flex justify-content-between">
                    <span>{{ tab.account_saving_no }}</span>
                  </div>
                </div>
              </div>
              <div v-if="saldo.tabungan.length < 1">
                <div class="bg-card-tabungan f-11 text-white p-2 m-2 bold">
                  <p class="text-center m-0">Anda tidak memiliki Tabungan</p>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title-link-class="'tab-title-class'">
            <template #title>
              <i class="mr-1 f-12">List </i>
              <strong class="f-12">Pembiayaan</strong>
            </template>
            <div
              class="d-flex justify-content-center align-items-center flex-column p-5"
              v-if="saldo.loading"
            >
              <b-spinner
                class="f-12"
                type="grow"
                label="Spinning"
                style="background-color: #159895"
              ></b-spinner>
              <span class="f-11 mt-1 bold">Loading....</span>
            </div>

            <div v-else>
              <div
                class="m-2"
                v-for="(pem, pemIndex) in saldo.pembiayaan"
                :key="`pem-${pemIndex}`"
              >
                <div class="bg-card-pembiayaan text-white p-2">
                  <h3 class="f-14 bold mb-0">
                    Pembiayaan ke {{ pem.pembiayaan_ke }}
                  </h3>
                  <span class="f-12 bold mb-3 d-block"
                    >Jangka Waktu {{ pem.jangka_waktu }}</span
                  >
                  <h4 class="f-14 bold d-flex justify-content-between">
                    Pokok : <span>{{ pem.pokok }}</span>
                  </h4>
                  <h4 class="f-14 bold d-flex justify-content-between">
                    Margin : <span>{{ pem.margin }}</span>
                  </h4>
                  <h5 class="f-13 bold d-flex justify-content-between">
                    Saldo Pokok : <span>{{ pem.saldo_pokok }}</span>
                  </h5>
                  <h5 class="f-13 bold d-flex justify-content-between">
                    Saldo Margin : <span>{{ pem.saldo_margin }}</span>
                  </h5>
                  <h5 class="f-13 bold d-flex justify-content-between">
                    Saldo Catab : <span>{{ pem.saldo_catab }}</span>
                  </h5>
                  <div class="f-11 bold d-flex justify-content-between">
                    <span>Status Rekening {{ pem.status_rekening }}</span
                    ><span>{{ pem.account_financing_no }}</span>
                  </div>
                </div>
              </div>
              <div v-if="saldo.pembiayaan.length < 1">
                <div class="bg-card-pembiayaan f-11 text-white p-2 m-2 bold">
                  <p class="text-center m-0">Anda tidak memiliki Pembiayaan</p>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <!-- <b-row no-gutters>
        <b-col class="p-3">
          <b-button
            :class="show == 'tabungan' ? 'button-sag f-11' : 'button-tab-na'"
            @click="show = 'tabungan'"
            block
            >Tabungan</b-button
          >
        </b-col>
        <b-col class="p-3">
          <b-button
            :class="show == 'pembiayaan' ? 'button-pyd' : 'button-pyd-na'"
            @click="show = 'pembiayaan'"
            block
            >Pembiayaan</b-button
          >
        </b-col>
      </b-row>
      <b-row class="m-0 mt-3" v-show="show == 'tabungan'">
        <b-col
          class="mb-3"
          v-for="(tab, tabIndex) in saldo.tabungan"
          :key="`tab-${tabIndex}`"
        >
          <div class="bg-card-cus-content text-white p-2">
            <h6>{{ tab.product_name }}</h6>
            <h2>{{ tab.saldo }}</h2>
            <div class="d-flex justify-content-between">
              <span>{{ tab.account_saving_no }}</span>
            </div>
          </div>
        </b-col>
        <b-col v-if="saldo.tabungan.length < 1">
          <div class="bg-card-cus-content text-white p-3">
            <p class="text-center m-0">Anda tidak memiliki Tabungan</p>
          </div>
        </b-col>
      </b-row>
      <b-row class="m-0 mt-3" v-show="show == 'pembiayaan'">
        <b-col
          class="mb-3"
          v-for="(pem, pemIndex) in saldo.pembiayaan"
          :key="`pem-${pemIndex}`"
        >
          <div class="bg-card-cus-content-pyd text-white p-2">
            <h3 class="mb-0">Pembiayaan ke {{ pem.pembiayaan_ke }}</h3>
            <span class="mb-3 d-block"
              >Jangka Waktu {{ pem.jangka_waktu }}</span
            >
            <h4 class="d-flex justify-content-between">
              Pokok: <span>{{ pem.pokok }}</span>
            </h4>
            <h4 class="d-flex justify-content-between">
              Margin: <span>{{ pem.margin }}</span>
            </h4>
            <h5 class="d-flex justify-content-between">
              Saldo Pokok: <span>{{ pem.saldo_pokok }}</span>
            </h5>
            <h5 class="d-flex justify-content-between">
              Saldo Margin: <span>{{ pem.saldo_margin }}</span>
            </h5>
            <h5 class="d-flex justify-content-between">
              Saldo Catab: <span>{{ pem.saldo_catab }}</span>
            </h5>
            <div class="d-flex justify-content-between">
              <span>Status Rekening {{ pem.status_rekening }}</span
              ><span>{{ pem.account_financing_no }}</span>
            </div>
          </div>
        </b-col>
        <b-col v-if="saldo.pembiayaan.length < 1">
          <div class="bg-card-cus-content-pyd text-white p-3">
            <p class="text-center m-0">Anda tidak memiliki Pembiayaan</p>
          </div>
        </b-col>
      </b-row> -->
    </div>
  </b-col>
</template>
<style>
.tab-title-class {
  text-transform: uppercase;
  font-size: 13px !important;
  color: black !important;
}
.tab-content {
  background-color: white !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-radius: 0 0 5px 5px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-10 {
  font-size: 10px;
}
.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}
.bg-card-tabungan {
  background-color: #216353;
  border: none;
  border-radius: 5px;
}
.bg-card-pembiayaan {
  background-color: #411530;
  border: none;
  border-radius: 5px;
}
</style>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      show: "tabungan",
      saldo: {
        pembiayaan: [],
        tabungan: [],
        loading: false,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getSaldoTabungan() {
      this.saldo.loading = true;
      let url = `https://anggota.baytulikhtiar.com/api/api/m_saldo_saving`;
      let payloadData = {
        cif_no: this.user.data.cif_no,
      };
      let payload = new FormData();
      for (let key in payloadData) {
        payload.append(key, payloadData[key]);
      }
      axios
        .post(url, payload)
        .then((res) => {
          if (res.data[0].status != "TIDAK ADA DATA")
            this.saldo.tabungan = res.data;
          this.saldo.loading = false;
        })
        .catch((res) => {
          this.saldo.loading = false;
          this.notif("Error", res.message, "danger");
        });
    },
    getSaldoPembiayaan() {
      this.saldo.loading = true;
      let url = `https://anggota.baytulikhtiar.com/api/api/m_saldo_financing`;
      let payloadData = {
        cif_no: this.user.data.cif_no,
      };
      let payload = new FormData();
      for (let key in payloadData) {
        payload.append(key, payloadData[key]);
      }
      axios
        .post(url, payload)
        .then((res) => {
          this.saldo.loading = false;
          if (res.data[0].status != "TIDAK ADA DATA")
            this.saldo.pembiayaan = res.data;
        })
        .catch((res) => {
          this.saldo.loading = false;
          this.notif("Error", res.message, "danger");
        });
    },
  },
  mounted() {
    this.getSaldoTabungan();
    this.getSaldoPembiayaan();
  },
};
</script>
